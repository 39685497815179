<template>
  <svg aria-hidden="true" :style="style">
    <use :href="symbolId" :fill="color" ref="svgIcon" />
  </svg>
</template>
<script setup>
import { computed, onMounted } from 'vue'
const svgIcon = ref(null)
const props = defineProps({
  prefix: {
    type: String,
    default: 'icon'
  },
  name: {
    type: String,
    required: true
  },
  size: {
    type: Number,
    default: 16
  },
  height: {
    type: Number,
    default: 0
  },
  color: {
    type: String,
    default: '#333'
  }
})
const symbolId = computed(() => `#${props.prefix}-${props.name}`)
const style = computed(() => {
  return {
    width: `${props.size}px`,
    height: `${props.height || props.size}px`,
    color: props.color
  }
})
</script>
