import { defineStore } from 'pinia'
import { getPersonalProfile, getPersonalPaymentInfo } from '@/api/personal'
import usePersonalStore from './personal'
const useAuthStore = defineStore({
  id: 'auth',
  state: () => {
    return {
      token: null,
      userinfo: null
    }
  },
  getters: {
    hasToken: state => {
      return Boolean(state.token)
    },
    hasAuth: state => {
      return Boolean(state.token && state.userinfo)
    },
    enough_credit: state => {
      return state.userinfo?.credits >= 10
    },
    is_free: state => {
      return ['', 'free'].includes(state.userinfo?.type)
    },
    is_payment: state => {
      return ['payment'].includes(state.userinfo?.type)
    },
    free_trials: state => {
      return state.userinfo?.number || 0
    },
    expiration_time: state => {
      return state.userinfo?.expiration_time
    }
  },
  actions: {
    // 存储授权
    setAuth({ token, userinfo }) {
      if (token) this.token = token
      if (userinfo) this.userinfo = userinfo
    },
    // 清除授权及缓存
    clearAuth() {
      this.token = null
      this.userinfo = null
      const personal = usePersonalStore()
      personal.clearAll()
    },
    // 获取用户信息/付费信息
    async getUserinfo() {
      if (!this.hasToken) return Promise.reject(Error('no auth'))
      try {
        const res = await Promise.all([getPersonalProfile(), getPersonalPaymentInfo()])
        if (res[0]?.err_code !== 0 || res[1]?.err_code !== 0) throw Error(res?.err_desc)
        this.setAuth({ userinfo: { ...this.userinfo, ...res[0]?.result, ...res[1]?.result } })
        return Promise.resolve(res)
      } catch (e) {
        return Promise.reject(e)
      }
    },
    // 获取用户基本信息
    async getInfo() {
      if (!this.hasToken) return Promise.reject(Error('no auth'))
      try {
        const res = await getPersonalProfile()
        if (res?.err_code !== 0) throw Error(res?.err_desc)
        this.setAuth({ userinfo: { ...this.userinfo, ...res?.result } })
        return Promise.resolve(res)
      } catch (e) {
        return Promise.reject(e)
      }
    },
    // 获取用户付费信息
    async updaetPaymentInfo() {
      if (!this.hasToken) return Promise.reject(Error('no auth'))
      try {
        const res = await getPersonalPaymentInfo()
        if (res?.err_code !== 0) throw Error(res?.err_desc)
        this.setAuth({ userinfo: { ...this.userinfo, ...res?.result } })
        return Promise.resolve(res)
      } catch (e) {
        return Promise.reject(e)
      }
    }
  }
})
export default useAuthStore
