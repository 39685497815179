<template>
  <van-config-provider :theme-vars="themeVars" theme="dark" :style="{ '--am-offset': `${hei}px` }">
    <router-view v-slot="{ Component }">
      <component :is="Component" />
    </router-view>
    <am-check-version />
    <am-network />
  </van-config-provider>
</template>
<script setup>
import { computed } from 'vue'
import vhCheck from 'vh-check'
import { useWindowSize } from '@vueuse/core'
import { watchDebounced } from '@vueuse/core'

const { height } = useWindowSize()
watchDebounced(
  height,
  () => {
    vhCheck()
  },
  { debounce: 200, maxWait: 1000 }
)
vhCheck()
const themeVars = reactive({
  navBarHeight: '44px',
  navBarBackground: '#000',
  tabbarHeight: '60px',
  tabbarBackground: '#000',
  tabbarItemActiveBackground: '#000',
  tabsNavBackground: '#000',
  tabTextColor: '#999',
  tabsLineHeight: '44px',
  tabbarItemFontSize: '11px',
  cellBackground: '#000',
  cellVerticalPadding: '15px',
  popupBackground: 'none',
  imagePlaceholderBackground: '#1A1A1A',
  tabsBottomBarColor: 'none',
  toastPositionBottomDistance: '70px',
})
const hei = computed(() => {
  return window.outerHeight - window.innerHeight
})
</script>
