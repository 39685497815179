/**
 * 阿里云arms监控
 */
import { version } from '../../package.json'
export default {
  install: async (app, options) => {
    if (import.meta.env.MODE === 'development') return
    const { default: BrowserLogger } = await import('alife-logger')
    if (!BrowserLogger) return
    const env = {
      production: 'prod',
      test: 'gray',
      development: 'local'
    }
    app.provide(
      '$bl',
      BrowserLogger.singleton({
        pid: 'bxe15xxdyb@03dd3e5c4d1eef1',
        appType: 'web',
        imgUrl: 'https://arms-retcode.aliyuncs.com/r.png?',
        sendResource: true,
        enableLinkTrace: true,
        behavior: true,
        useFmp: true,
        enableSPA: true,
        enableConsole: true,
        release: version,
        /**
         * 环境定义（业务必填）
         * 线上使用prod，测试环境使用pre，开发环境使用local
         * prod表示线上环境。----对应线上环境
         * gray表示灰度环境。
         * pre表示预发环境。----对应test环境
         * daily表示日常环境。----对应debug环境
         * local表示本地环境。
         */
        environment: env[import.meta.env.MODE] || 'local'
      })
    )
  }
}
