<template>
  <router-view v-slot="{ Component }">
    <component :is="Component" />
  </router-view>
  <van-tabbar
    route
    fixed
    safe-area-inset-bottom
    placeholder
    z-index="10000"
    inactive-color="#999"
    active-color="#A1F4FF"
  >
    <van-tabbar-item replace :to="{ name: 'face-hi' }">
      <template #icon="props">
        <div class="w-7 h-7 relative">
          <transition name="van-fade">
            <am-svg-icon :class="icon_class" v-if="props.active" name="home_active" :size="28" />
            <am-svg-icon :class="icon_class" v-else name="home" :size="28" />
          </transition>
        </div>
      </template>
      Trendy
    </van-tabbar-item>
    <van-tabbar-item replace :to="{ name: 'face-swap' }">
      <template #icon="props">
        <div class="w-7 h-7 relative">
          <transition name="van-fade">
            <am-svg-icon :class="icon_class" v-if="props.active" name="swap_active" :size="28" />
            <am-svg-icon :class="icon_class" v-else name="swap" :size="28" />
          </transition>
        </div>
      </template>
      Upload
    </van-tabbar-item>
    <van-tabbar-item replace :to="{ name: 'profile' }">
      <template #icon="props">
        <div class="w-7 h-7 relative">
          <transition name="van-fade">
            <am-svg-icon :class="icon_class" v-if="props.active" name="mine_active" :size="28" />
            <am-svg-icon :class="icon_class" v-else name="mine" :size="28" />
          </transition>
        </div>
      </template>
      Profile
    </van-tabbar-item>
  </van-tabbar>
</template>
<script setup>
import useAuthStore from '@/store/modules/auth'
import { ref } from 'vue'
const auth = useAuthStore()
const icon_class = ref('w-7 h-7 absolute')
</script>
