import request from './request'

// 查询创作作品列表
export const getPersonalCreations = params => {
  return request('/api/v1/personal/creation/creations', 'get', params)
}

// 查询创作作品数量
export const getPersonalCreationsCount = params => {
  return request('/api/v1/personal/creation/creations.count', 'get', params)
}

// 删除作品
export const deletePersonalCreations = uid => {
  return request(`/api/v1/personal/creation/creations/${uid}`, 'delete')
}

// 登出
export const personalLogout = () => {
  return request(`/api/v1/personal/logout`, 'post')
}

// 获取用户信息
export const getPersonalProfile = () => {
  return request(`/api/v1/personal/profile`, 'get')
}

// 更新用户信息
export const updatePersonalProfile = data => {
  return request(`/api/v1/personal/profile`, 'patch', null, data)
}

// 获取用户付费信息
export const getPersonalPaymentInfo = data => {
  return request(`/api/v1/personal/payment_info`, 'get')
}

// 作品评价
export const creationsLike = (uid, data) => {
  return request(`/api/v1/personal/creation/creations/${uid}/like`, 'post', '', data)
}
