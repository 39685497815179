<template>
  <van-notify :show="!online" type="warning">
    <van-icon name="info" class="mr-2" />
    <span
      >The current network status is abnormal. Please check if the network connection is
      normal.</span
    >
  </van-notify>
</template>
<script setup>
const online = useOnline()
</script>
