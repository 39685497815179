/**
 * vue route路由拦截中间件
 */
import { isNavigationFailure, NavigationFailureType } from 'vue-router'
import nProgress from 'nprogress'
import 'nprogress/nprogress.css'
import useAuthStore from '@/store/modules/auth'
export const white_list = [
  'index',
  'face-hi',
  'face-swap',
  'credit',
  'auth',
  'login',
  'signup',
  'password-reset',
  'privacy-policy',
  'term-of-service'
]
const interception = router => {
  const login_list = ['login', 'signup']
  nProgress.configure({ showSpinner: false })
  // 路由错误触发
  router.onError(val => {
    console.log(val)
  })
  // 路由跳转前触发拦截
  router.beforeEach(async (to, from, next) => {
    const authStore = useAuthStore()
    // const faceswapStore = useFaceswapStore()
    if (!authStore.hasAuth && !white_list.includes(to.name)) {
      // 未授权 =》 跳转到登录页
      next({ name: 'login', query: { from: from?.name } })
    } else if (authStore.hasAuth && login_list.includes(to.name)) {
      // 授权 =》 访问登录页、注册页跳转至首页
      next({ name: 'face-hi' })
    } else if (authStore.hasAuth && authStore?.is_payment && to.name === 'credit') {
      // 已是会员 进入订阅页 跳转到首页
      next({ name: 'face-hi' })
    } else {
      next()
    }
    // else if(to.name === 'swap' && !faceswapStore?.process?.status && !faceswapStore.loading){
    //   next({ name: 'face-swap' })
    // }
    nProgress.start()
  })

  // 路由跳转后触发拦截
  router.afterEach((to, from, failure) => {
    nProgress.done()
    // 任何类型的导航失败
    if (isNavigationFailure(failure)) {
      // console.log(failure)
      // ...
    }
    // 重复的导航
    if (isNavigationFailure(failure, NavigationFailureType.duplicated)) {
      // ...
      // console.log(failure)
    }
    // 中止或取消的导航
    if (
      isNavigationFailure(failure, NavigationFailureType.aborted | NavigationFailureType.canceled)
    ) {
      // ...
      // console.log(failure)
    }
  })
}

export default interception
