/**
 * vue route 路由管理
 * 地址：https://next.router.vuejs.org/
 */
import { createRouter, createWebHashHistory } from "vue-router";
import routes from "./routes";
import interception from './interception'

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior(to, from, savaPosition) {
    return savaPosition
  },
});

interception(router)
export default router;
