import Cookie from 'js-cookie'
import { name } from '../../../package.json'
const token_key = `${name}-token`
const userinfo_key = `${name}-userinfo`

const authStorePlugin = ({ store }) => {
  const store_name = store.$id
  const token = Cookie.get(token_key)
  const userinfo = Cookie.get(userinfo_key)
  if (store_name === 'auth') {
    if (token && userinfo) {
      store.setAuth({ token,userinfo: JSON.parse(userinfo) })
    } else {
      store.clearAuth()
    }
  }
  store.$onAction(({ name, args }) => {
    switch (name) {
      case 'setAuth':
        const { token, userinfo } = args[0]
        if (token) Cookie.set(token_key, token, { expires: 7 })
        if (userinfo) Cookie.set(userinfo_key, JSON.stringify(userinfo), { expires: 7 })
        break
      case 'clearAuth':
        Cookie.remove(token_key)
        Cookie.remove(userinfo_key)
        break
    }
  })
}

export default authStorePlugin
