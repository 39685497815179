/**
 * 自定义埋点插件
 */
import axios from 'axios'
import dayjs from 'dayjs'
import { useUrlSearchParams } from '@vueuse/core'
import { nanoid } from 'nanoid'
const session_id_key = 'facehi_session_id'
const utm_key = 'facehi_utm'
const user_uid_key = 'am-tracker-user-uid'
class amTracker {
  constructor(url, identify) {
    // utm
    const history = useUrlSearchParams('history')
    const hash = useUrlSearchParams('hash')
    let utm = ''
    const utm_keys = ['utm_source', 'utm_medium', 'utm_term', 'utm_content', 'utm_campaign']
    utm_keys.forEach((key, i) => {
      if (history[key] || hash[key] || '') {
        if (utm) {
          utm += '&'
        }
        utm += `${key}=${history[key] || hash[key] || ''}`
      }
    })
    if (utm) localStorage.setItem(utm_key, utm)
    this.utm = localStorage.getItem(utm_key) || utm
    // session_id
    const session_id = sessionStorage.getItem(session_id_key)
    if (!session_id) {
      const uuid = nanoid()
      sessionStorage.setItem(session_id_key, uuid)
      this.session_id = uuid
    } else {
      this.session_id = session_id
    }
    this.url = url
    this.identify = identify
    let user_uid = localStorage.getItem(user_uid_key)
    if (user_uid) {
      this.user_uid = user_uid
    }
  }
  setUser(user_uid) {
    this.user_uid = user_uid
    localStorage.setItem(user_uid_key, user_uid)
  }
  track(data) {
    const { event_type, current_url = window.location.href, object_type, object_id, utm = this.utm } = data
    axios({
      method: 'post',
      url: this.url,
      data: {
        session_id: this.session_id,
        user_uid: this.user_uid,
        event_type: event_type,
        object: {
          current_url,
          object_type,
          object_id
        },
        event_time: dayjs().unix(),
        utm,
        identify: this.identify
      }
    })
  }
  pageEnterEvent({ current_url, object_id, utm }) {
    this.track({ event_type: '进入页面', current_url, object_type: 'page', object_id, utm })
  }
  clickEvent({ current_url, object_id, utm }) {
    this.track({ event_type: '点击事件', current_url, object_type: 'btn', object_id, utm })
  }
}
export const createAmTracker = ({ url, identify }) => {
  let tracker = new amTracker(url, identify)
  const pageEnterEvent = {
    mounted: (el, bind) => {
      tracker.pageEnterEvent({ ...bind.value })
    }
  }
  const clickEvent = {
    mounted: (el, bind) => {
      el.addEventLinstener('click', tracker.clickEvent({ ...bind.value }))
    },
    unmounted: el => {
      el.removeEventLinstener('click', tracker.clickEvent({ ...bind.value }))
    }
  }
  return {
    install: (app, options) => {
      app.provide('amTracker', tracker)
      app.directive('am-page-enter', pageEnterEvent)
      app.directive('am-click', clickEvent)
    }
  }
}
