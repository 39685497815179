import home from '@/views/home/index.vue'

const routes = [
  {
    path: '/',
    name: 'index',
    meta: { title: 'index', keepAlive: false },
    redirect: 'face-hi'
  },
  {
    path: '/',
    name: 'home',
    component: home,
    meta: { title: 'home', keepAlive: false },
    redirect: 'face-hi',
    children: [
      {
        path: '/face-hi',
        name: 'face-hi',
        component: () => import('@/views/face-hi/index.vue'),
        meta: { title: 'FaceHi', keepAlive: false }
      },
      {
        path: '/face-swap',
        name: 'face-swap',
        component: () => import('@/views/face-swap/upload.vue'),
        meta: { title: 'Face Swap', keepAlive: false }
      },
      {
        path: '/profile',
        name: 'profile',
        component: () => import('@/views/mine/profile.vue'),
        meta: { title: 'Creations', keepAlive: false }
      }
    ]
  },
  {
    path: '/swap',
    name: 'swap',
    component: () => import('@/views/face-swap/swap.vue'),
    meta: { title: 'Face Swap', keepAlive: false }
  },
  {
    path: '/profile/action',
    name: 'profile-action',
    component: () => import('@/views/mine/action.vue'),
    meta: { title: '', keepAlive: false }
  },
  {
    path: '/creations-detail',
    name: 'creations-detail',
    component: () => import('@/views/mine/creations-detail.vue'),
    meta: { title: '', keepAlive: false }
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/auth/login.vue'),
    meta: { title: 'login', keepAlive: false }
  },
  {
    path: '/signup',
    name: 'signup',
    component: () => import('@/views/auth/signup.vue'),
    meta: { title: 'signup', keepAlive: false }
  },
  {
    path: '/password-reset',
    name: 'password-reset',
    component: () => import('@/views/auth/password-reset.vue'),
    meta: { title: 'password-reset', keepAlive: false }
  },
  {
    path: '/change-email',
    name: 'change-email',
    component: () => import('@/views/mine/change-email.vue'),
    meta: { title: '', keepAlive: false }
  },
  // {
  //   path: '/subscribe',
  //   name: 'subscribe',
  //   component: () => import('@/views/subscribe/index.vue'),
  //   meta: { title: 'subscribe', keepAlive: false }
  // },
  {
    path: '/credit',
    name: 'credit',
    component: () => import('@/views/credit/index.vue'),
    meta: { title: 'credit', keepAlive: false }
  },
  {
    path: '/profile/my',
    name: 'profile-my',
    component: () => import('@/views/mine/my.vue'),
    meta: { title: 'My Profile', keepAlive: false }
  },
  {
    path: '/contact-us',
    name: 'contact-us',
    component: () => import('@/views/mine/contact-us.vue'),
    meta: { title: 'Contact us', keepAlive: false }
  },
  {
    path: '/common/privacy-policy',
    name: 'privacy-policy',
    component: () => import('@/views/common/privacy-policy.vue'),
    meta: { title: '', keepAlive: false }
  },
  {
    path: '/common/term-of-service',
    name: 'term-of-service',
    component: () => import('@/views/common/term-of-service.vue'),
    meta: { title: '', keepAlive: false }
  },
  {
    path: '/common/support',
    name: 'common-support',
    component: () => import('@/views/common/support.vue'),
    meta: { title: 'Support', keepAlive: false }
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    redirect: { name: 'home' }
  }
]
export default routes
