import useAuthStore from '@/store/modules/auth'
import router from '@/router'
export const windowMessage = () => {
  window.addEventListener('message', deleteSuccess, false)
  function deleteSuccess(e) {
    let { origin, data } = e
    // 来自注销单页 -》 删除成功
    if (origin === import.meta.env.VITE_DELETION_HOST) {
      const auth = useAuthStore()
      auth.clearAuth()
      router.replace({ name: 'login' })
    }
  }
}
