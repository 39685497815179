import { createApp } from 'vue'
import App from '@/App.vue'
import 'virtual:svg-icons-register'
import '@/styles/index.js'
import router from '@/router/index'
import store from '@/store/index'
import arms from '@/plugins/arms'
import Vue3Lottie from 'vue3-lottie'
import { createAmTracker } from '@/plugins/amTracker'
import { Locale, Lazyload } from 'vant'
import { windowMessage } from '@/utils/onMessage'
import enUS from 'vant/es/locale/lang/en-US'
Locale.use('en-US', enUS)

const amTracker = createAmTracker({
  url: import.meta.env.VITE_TRACKER_HOST || '',
  identify: 'FaceHi_H5'
})
windowMessage()
const runApp = () => {
  const app = createApp(App)
  app
    .use(router)
    .use(store)
    .use(arms)
    .use(Vue3Lottie)
    .use(amTracker)
    .use(Lazyload, { lazyComponent: true })
    .mount('#app')
}
runApp()
// app.config.errorHandler = (err, instance, info) => {
//   console.error('errorHandler', err)
// }

// app.config.warnHandler = (err, instance, info) => {
//   console.warn('warnHandler', err)
// }
