import axios from 'axios'
import router from '@/router'
import useAuth from '@/store/modules/auth'
const host = import.meta.env.VITE_HOST || ''
const service = axios.create({
  baseURL: host,
  timeout: 1000 * 60 * 10 // 请求超时时间
})
// request拦截器
service.interceptors.request.use(
  config => {
    config.headers['Language'] = 'en'
    const auth = useAuth()
    if (auth?.token) config.headers['Authorization'] = auth?.token
    return config
  },
  error => {
    Promise.reject(error)
  }
)
let timer = null
// respone拦截器
service.interceptors.response.use(
  response => {
    // 授权失败 跳出
    const route = router?.currentRoute?.value?.name
    if (response.data.err_code === 1001 && route.name !== 'login') {
      const auth = useAuth()
      auth.clearAuth()
      router.push({ name: 'login' })
    }
    return response.data
  },
  error => {
    let err = ''
    switch (error?.response?.status) {
      case 400:
        err = 'Bad request'
        break
      case 401:
        err = 'Unauthorized, please log in again'
        break
      case 403:
        err = 'No access'
        break
      case 404:
        err = 'Request error, the resource was not found'
        break
      case 405:
        err = 'Request method not allowed'
        break
      case 408:
        err = 'Request timeout'
        break
      case 500:
        err = 'Server side error'
        break
      case 501:
        err = 'Network not implemented'
        break
      case 502:
        err = 'Network error'
        break
      case 503:
        err = 'The service is unavailable'
        break
      case 504:
        err = 'Network Timeout'
        break
      case 505:
        err = 'The HTTP version does not support this request'
        break
      default:
        err = `Unknown error`
    }
    return Promise.reject(Error(err))
  }
)

const request = (url, method, params, data, options) => {
  return service({
    url,
    method,
    params,
    data,
    ...options
  })
}
export default request
