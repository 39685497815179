import { defineStore } from 'pinia'
import { getPersonalCreations, getPersonalCreationsCount } from '@/api/personal'
const usePersonalStore = defineStore({
  id: 'personal',
  state: () => {
    return {
      active: 0,
      creations: [
        {
          name: 'Creations',
          type: 'video',
          list: [],
          count: 0,
          loading: false,
          finished: false,
          refreshing: false,
          error: false,
          y: 0
        },
      ]
    }
  },
  getters: {},
  actions: {
    refresh(val) {
      const current = this.creations[val]
      if (current.loading) return
      current.error = false
      current.refreshing = true
      this.getCreations(current?.type)
    },
    async getCreations(type) {
      const current = this.creations.find(item => item.type === type)
      try {
        if (current.refreshing) {
          current.list = []
          current.refreshing = false
          current.finished = false
          current.error = false
        }
        current.loading = true
        const res = await getPersonalCreationsCount({ type })
        if (res?.err_code !== 0) throw Error(res?.err_desc)
        current.count = res.result?.count
        if (current.count <= current.list?.length) {
          current.finished = true
          return
        }
        const _res = await getPersonalCreations({ type, pl: `${10},${current?.list?.length}` })
        if (_res?.err_code !== 0) throw Error(_res?.err_desc)
        if (_res.result?.creations?.length <= 0) {
          current.finished = true
          return
        }
        current.list.push(..._res.result?.creations)
        if (current.list?.length >= current.count) {
          current.finished = true
        }
        current.error = false
      } catch (e) {
        current.error = true
        return Promise.reject(e)
      } finally {
        current.loading = false
      }
    },
    clearAll() {
      this.creations.forEach(item => {
        item.list = []
        item.count = 0
        item.y = 0
        item.finished = false
        item.error = false
      })
    },
    updateCreationLike(uid,like){
      if(!uid) return
      const index = this.creations[0]?.list?.findIndex(item => item.uid === uid)
      if(index < 0) return
      this.creations[0].list[index].like = like
    }
  }
})
export default usePersonalStore
